<template>

	<div class="fixed-button" style="text-align: center">
		<el-popover
				placement="left"
				width="200"
				trigger="click"
				popper-class="popperClass"
		>
			<div ><img src="../../assets/qrCode1.png" style="width: 200px;height: 200px"> <br><span style="font-size: 22px">{{'在线时间：'}}<br>{{'工作日 9:00-18:00' }}</span></div>
			<el-button slot="reference"  class="circle-button" type="text" @click=""><i class="el-icon-service" style="font-size: 50px"></i></el-button>
		</el-popover>
		<br>
<!--		<el-popover-->
<!--				placement="left"-->
<!--				width="200"-->
<!--				trigger="click"-->
<!--				popper-class="popperClass"-->
<!--		>-->
<!--			<div style="text-align: center"><el-button type="text" size="medium" @click="downloadA">-->
<!--				<span style="font-size: 22px">{{'下载操作手册' }}</span>-->
<!--			</el-button></div>-->
<!--			<el-button slot="reference"  class="circle-button" type="text" @click=""><i class="el-icon-document" style="font-size: 50px"></i></el-button>-->
<!--		</el-popover>-->
		<el-button type="text" size="medium" @click="downloadA">
			<span style="font-size: 22px">{{'操作'}}<br>{{'手册' }}</span>
		</el-button>
		<br>
<!--		<el-popover-->
<!--				placement="left"-->
<!--				width="200"-->
<!--				trigger="click"-->
<!--				popper-class="popperClass"-->
<!--		>-->
<!--			<div style="font-size: 22px">{{'操作视频' }}</div>-->
<!--			<el-button slot="reference"  class="circle-button" type="text" @click=""><i class="el-icon-film" style="font-size: 50px"></i></el-button>-->
<!--		</el-popover>-->
		<el-button type="text" size="medium" @click="">
			<span style="font-size: 22px">{{'操作'}}<br>{{'视频' }}</span>
		</el-button>
	</div>

</template>
<script>


export default {
	methods:{
		downloadA(){
			window.open(
					'https://qdzn-pro.obs.cn-east-3.myhuaweicloud.com/docs/%E6%B6%88%E9%98%B2%E5%AE%89%E5%85%A8-AI%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD%E5%9B%BE%E5%AE%A1%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.docx'
			)
		}
	}
}
</script>

<style lang="stylus" scoped>
.fixed-button {
	position: fixed; /* Fixed positioning */
	right: 0;        /* Stick to the right edge */
	top: 50%;        /* Position at the vertical center */
	transform: translateY(-50%); /* Move up by 50% of its height to center vertically */
}

</style>