import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';

let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';

// 请求延时
axios.defaults.timeout = 60000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        // if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
        if (window.localStorage.getItem('Token')) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + window.localStorage.getItem('Token');
        }
        // console.log(store,'store')
        saveRefreshtime();
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // 在用户操作的活跃期内
            if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                return refreshToken({ token: window.localStorage.Token }).then((res) => {
                    if (res.success) {
                        store.commit("saveToken", res.response.token);
                        var curTime = new Date();
                        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                        store.commit("saveTokenExpire", expiredate);
                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = 'Bearer ' + res.response.token;
                        return axios(error.config);
                    } else {
                        ToLogin()
                        return false;
                    }
                })
                .catch((err) => {
                    ToLogin()
                    return false;
                })
            } else {
                ToLogin()
                return false;
            }
        } else if (response.data.status == 403) {
            Vue.prototype.$message({
                message: '失败！该操作无权限',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 429) {
            Vue.prototype.$message({
                message: '刷新次数过多，请稍事休息重试！',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 500) {
            setTimeout(function () {
                Vue.prototype.$message({
                    message: '服务器错误，请稍后重试！',
                    type: 'error'
                });
            }, 1000)
            return null;
        }
        return response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });
            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return refreshToken({ token: window.localStorage.Token }).then((res) => {
                        if (res.success) {
                            // Vue.prototype.$message({
                            //     message: 'refreshToken success! loading data...',
                            //     type: 'success'
                            // });
                            store.commit("saveToken", res.response.token);
                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                            store.commit("saveTokenExpire", expiredate);
                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                            return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                    return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                }
            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function () {
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                }, 1000)
                return null;
            }
        }
        return ""; // 返回接口返回的错误信息
    }
);
const ToLogin = params => {
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');
    var routerGo = router.currentRoute.fullPath.substring(0, 6)
    if (routerGo == "/login") {
    } else {
        router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
        });
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });
    // 调试登录过期后重新登录第一次无法跳转到相应的页面，而是停留在登录页面，所以暂时注释，怀疑重新加载所以看不到console.log("redirect_api--") date:2020-12-23
    window.location.reload()
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};
export const BaseApiUrl = base;
export const saveRefreshtime = params => {
    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))
    let refreshCount = 1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime = nowtime > expiretime ? nowtime : expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    } else {
        window.localStorage.refreshtime = new Date(-1);
    }
};

// 系统登录
export const requestLogin = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/login`, params).then(res => res.data);
};
//注册
export const registerProjectAccount = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/registerProjectAccount`, params);
};

//刷新token
export const refreshToken = params => {
    return axios.get(`${base}/aidrwebapi/login/refreshToken`, { params: params }).then(res => res.data);
};

// 获取图形验证码
export const captchaImage = params => {
    return axios.get(`${base}/aidrwebapi/captcha/captchaImage`, {params: params}).then(res => res.data);
};


//获取用户信息
export const getAccountInfo = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getAccountInfo`, { params: params });
};

//获取项目列表
export const getProjectList = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getProjectList`, { params: params });
};

//新增开业检查申请表
export const addProject = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/addProject`, params);
};

//发送短信验证码
export const sendSMSVerificationCode = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/sendSMSVerificationCode`,  params );
};

//重置密码
export const resetProjectAccountPassword = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/resetProjectAccountPassword`, params);
};

//获取所有区县列表
export const getContractList = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getContractList`, { params: params });
};

//获取开业检查项目申报表详情
export const getApplicationByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getApplicationByProjectId`, { params: params });
};

//更新开业检查项目申报表
export const updateProject = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/updateProject`, params);
};

//获取文件结构
export const getProjectFileInfo = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getProjectFileInfo`, { params: params });
};

//添加项目文件
export const addProjectFile = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/addProjectFile`, params);
};

//删除项目文件
export const deleteProjectFile = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/deleteProjectFile`, params);
};

//生成临时Url链接
export const generateTemporaryUrl = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/generateTemporaryUrl`, { params: params });
};

//提交项目
export const submitProject = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/submitProject`, params);
};

//获取省份列表
export const getAreaDictionaryListWithCode = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getAreaDictionaryListWithCode`, { params: params });
};
//根据区县条件获取合同记录
export const getContractByAreaCode = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getContractByAreaCode`, { params: params });
};

//识别word申请表
export const ocrApplicationWord = `${base}/aidrwebapi/kyjc/ocrApplicationWord`
