<template>
    <div class="wrapper">
	    <div class="headerContainer" style="font-size: 20px">
		    <img src="../assets/logo.png"  width="40px" alt="">
		    <div style="display: inline-block;margin-left: 10px;font-weight: bold">消防安全检查-AI人工智能图审系统</div>
		    <div > &nbsp;|&nbsp; 登录</div>
	    
	    </div>
	    <div class="parent" style="font-size: 20px">
		    <div class='child'>
			    <div  class="" style="font-weight: bold;margin-left:38%;padding-bottom:6px ; ">
				    登录
			    </div>
		    </div>
	    </div>
        <div style="height: 8%;"></div>
        <div  style="height: 66vh">
            <el-form style="width: 30%" :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px" class="demo-ruleForm login-container">
                <h3 class="title">系统登录</h3>
                <el-form-item prop="account">
                    <el-input type="text" v-model="ruleForm2.account" auto-complete="off" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                    <el-input v-model="ruleForm2.checkPass" auto-complete="off"  show-password placeholder="密码"></el-input>
                </el-form-item>
	              <div style="display: flex;">
		              <el-form-item prop="QRcode">
			              <el-input v-model="ruleForm2.QRcode" style="width: 300px;" @keyup.enter.native="enterKey" auto-complete="off" placeholder="请输入验证码"></el-input>
		              </el-form-item>
		              <div v-loading="imageLoading">
			              <img :src="codeImage" @click="refreshCode" style="width: 150px;height: 40px;margin-left:20px;" alt="验证码">
		              </div>
	              </div>
	            <div class="formeSelec">
		            <div style="display:flex;justify-content:space-between;margin-top: 20px;">
			             <el-button type="text" @click="AccApassLogin">注册</el-button>
			            <el-button type="text" @click="resetPassword">忘记密码？</el-button>
		            </div>
	            </div>
                <el-form-item style="width:100%;">
                    <el-button type="primary" style="width:100%;" @click="handleSubmit2" :loading="logining">
                        {{loginStr}}
                    </el-button>
                </el-form-item>
            </el-form>
		        <PageComponents/>
        </div>
	    <div >
		    <el-footer style="text-align: center; background-color: #EAEBEB; padding: 10px;margin-bottom: 0; left: 0; width: 100%;">
			    <div style="display: flex; align-items: center;justify-content: space-around; width: 80%;margin-left: 10%">
				    <div style=" display: flex;align-items: center">
					    <div>技术支持：</div>
					    <img src="../assets/heibai.png"  style="height: 40px" alt="">
				    </div>
				    <div>
					    Copyright © 2014-2024
				    </div>
				    <div>
					    沪ICP备2022031715号
				    </div>
<!--				    <div>-->
<!--					    赣公网安备 3607012000119号-->
<!--				    </div>-->
			    </div>

		    </el-footer>
	    </div>
    </div>
</template>
<script>
import {requestLogin,captchaImage,} from '../api/api';
import router from '../router'
import util from '../../util/date'
import Cookies from 'js-cookie'
import PageComponents from './XieYi/pagecomponents.vue'
import {validPhone, valididentityCard, validPassword} from "../../util/validate";
export default {
	components: {
		PageComponents
},
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
	    let isallowPassword = (rule, value, callback) => {
		    if (!validPassword(value)) {
			    return callback(new Error("至少为8个字符，其中包含数字、大写字母或小写字母"));
		    } else {
			    callback();
		    }
	    };
        return {
	        imageLoading:false,
	        codeImage: '',
            utils: '',
            loginStr: '登录',
            logining: false,
            ruleForm2: {
	            account: '',
	            checkPass: '',
	            QRcode:undefined,
            },
	        rules2: {
		        account: [
			        { required: true, message: '请填写手机号', trigger: 'blur'  },
			        { validator: isPhoneNumberNum, trigger: 'blur' }
		        ],
		        checkPass: [
			        { required: true, message: '至少为8个字符，其中包含数字、大写字母或小写字母', trigger: 'blur' },
			        { validator:isallowPassword, trigger: 'blur'}
		        ]
	        },
            checked: false,
            // 选择登录方式
            moreLoginDialog: 0,
            LoginSMS: false,
            codeLogining: false,
            getVerCode: '获取验证码',
            getVerCode1: '',
            phoneSubmitLogining: false,
            accountArr: [], //账号集合
            accountOne: '', //一个账号
            // phoneForm: {
            //     userPhone: '',
            //     verificationCode: '',
            // },
            // phoneFormRules: {
            //     userPhone: [
            //         {required: true, message: '请输入手机号', trigger: 'blur'},
            //         { validator: isPhoneNumberNum },
            //     ],
            //     verificationCode: [
	          //       { required: true, message: '请输入数字和密码，且长度不小于8位', trigger: 'blur' },
	          //       {
		        //         validator: (rule, value, callback) => {
			      //           const regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
			      //           if (value.match(regex)) {
				    //             callback();
			      //           } else {
				    //             callback(new Error('密码格式不正确'));
			      //           }
		        //         },
		        //         trigger: 'blur'
	          //       }
            //     ],
            // },
	        uuid:'',
        };
    },
    methods:{
	    QYScancodeLogin(){
		    this.$router.push({
			    path: '/callBackLogin',
		    })
	    },
			//注册
	    AccApassLogin () {
        this.$router.push({
          path: '/registrationPage',
        })
      },
      //忘记密码
      resetPassword (){
        this.$router.push({
          path: '/resetPage',
        })
      },
	    // 刷新验证码
	    refreshCode() {
		    this.getQRcodeFun();
	    },
	    // 获取图形验证码
	    getQRcodeFun() {
		    var params = {}
		    this.imageLoading = true
		    captchaImage(params).then(res => {
			    let result = res
			    if(result.success) {
				    this.codeImage = "data:image/jpg;base64," + result.response.img
				    this.uuid = result.response.uuid
				    this.imageLoading = false
			    }
		    })
	    },
        // 回车
        enterKey() {
            if(!this.logining){
                this.handleSubmit2();
            }
        },
        // 获取 Token
        handleSubmit2(ev) {
	        this.$refs.ruleForm2.validate((valid) => {
		        var _this = this;

		        if (this.ruleForm2.account && this.ruleForm2.checkPass) {
			        this.logining = true;
			        var loginParams = {
				        account: this.ruleForm2.account,//账号
				        password: this.ruleForm2.checkPass,//密码
				        uuid: this.uuid,//验证码id
				        code: this.ruleForm2.QRcode,//验证码
			        };
			        _this.loginStr = "登录中...";
			        console.log(loginParams, 'loginParams')
			        requestLogin(loginParams).then(data => {
						        console.log(data);
						        if (!data.success) {
							        _this.$message({
								        message: data.message,
								        // message: '用户名或密码错误！',
								        type: 'error'
							        });
											this.refreshCode()
							        _this.logining = false;
							        _this.loginStr = "重新登录";
							        // _this.closeAlert()
						        } else {
							        _this.logining = false;
							        _this.loginStr = "登录成功";
							        var token = data.response.token;
							        _this.$store.commit("saveToken", token);
							        var curTime = new Date();
							        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + data.response.expiresIn));
							        _this.$store.commit("saveTokenExpire", expiredate);
							        window.localStorage.refreshtime = expiredate;
							        window.localStorage.expiresIn = data.response.expiresIn;
							        // _this.closeAlert()
							        // _this.openAlert("成功获取Token，等待服务器返回用户信息...")
							        _this.loginStr = "成功获取Token，等待服务器返回用户信息...";
							        let redirectPath = this.$route.query.redirect || '/';
							        this.$router.push(redirectPath);
							        // _this.getUserInfoByToken(token)
						        }
					        })
					        .catch(
							        setTimeout(function () {
								        _this.logining = false
										        _this.loginStr = "重新登录"
							        }, 5000)
					        )
		        } else {
			        _this.$message.warning("请输入用户名或密码！")
		        }
	        })
        },
        // 获取用户数据
        getUserInfoByToken(token) {
            var _this = this;

            var loginParams = {token: token};
            getUserByToken(loginParams).then(data => {
                if (!data.success) {
                    _this.$message({
                        message: data.message,
                        type: 'error'
                    });
                } else {
                    // _this.closeAlert()
                    // _this.openAlert("接收到用户数据，开始初始化路由树...")
                    if(data.response.isInitialization){
                        router.replace({
                            path: "/callBackLogin",
                            query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
                        });
                    }else{
                        // 记住密码
                        if(this.checked){
                            var strMi = this.ruleForm2.account+'|'+this.ruleForm2.checkPass
                            var MiStr = util.compileStr(strMi)
                            Cookies.set('ASP.pass',MiStr,{expires: 7,});
                        }else{
                            Cookies.remove('ASP.pass');
                        }
                        _this.loginStr = "接收到用户数据，开始初始化路由树...";
                        window.localStorage.user = JSON.stringify(data.response)
		                    router.replace({
			                    path: "/",
			                    query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
		                    });
                    }
                }
            });
        },
    },
    created() {
        this.utils = util
        var ASP_Pass = Cookies.get("ASP.pass");
        var OpenStr = util.uncompileStr(ASP_Pass)
	      this.refreshCode()
        if(ASP_Pass){
            this.ruleForm2.account = util.getCaption(OpenStr,0)
            this.ruleForm2.checkPass = util.getCaption(OpenStr,1)
            this.checked = true
        }else{
            this.checked = false
        }
    }
}
</script>
<style scoped>
.parent {
	height: 70px;
	background-color: rgb(67, 146, 244);
	display: flex;
	justify-content: left;
	align-items: flex-end;
	margin-bottom: 20px;
	
}
.child {
	margin-left :10%;
	width: 200px;
	height: 40px;
	background-color: white;
	display: flex;
	justify-content: left;
	align-items: flex-end;
}

.login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: auto;
    width: 400px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    z-index: 9999;
    position: relative;
}
.login-container .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
}
.login-container .remember {
    margin: 0px 0px 25px 0px;
}





.headerContainer{
	display: flex;
	align-items: center;
	margin: 10px 0 10px 10%;
	justify-content: left;
}

</style>
